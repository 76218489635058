
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: null
    },
    hideCancel: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closed'],
  methods: {
    windowClicked (event: any) {
      event.windowClicked = true
    },
    close (event: any) {
      if (!event.windowClicked) this.$emit('closed')
    }
  }
})
