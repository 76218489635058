
import { defineComponent } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import Sidebar from '@/shared/components/Sidebar.vue'
import { NavigationItem } from '@/shared/interfaces/navigation.model'
import { Branch } from '@/shared/interfaces/branch.model'
import Modal from '@/shared/components/Modal.vue'

export default defineComponent({
  name: 'Account',
  components: {
    Sidebar,
    Modal
  },
  data: function () {
    return {
      navItems: [
        {
          iconName: 'book',
          routeTo: '/app/',
          routeText: 'nav.book'
        }, {
          iconName: 'fridge',
          routeTo: '/app/data/day-temperatures/fridge',
          routeText: 'nav.fridgesTemperatures'
        }, {
          iconName: 'freezer',
          routeTo: '/app/data/day-temperatures/freezer',
          routeText: 'nav.freezersTemperatures'
        }, {
          iconName: 'cleaning',
          routeTo: '/app/data/cleaning',
          routeText: 'nav.cleaning'
        }, {
          iconName: 'cleaningschedule',
          routeTo: '/app/data/cleaning-schedule',
          routeText: 'nav.cleaningSchedule'
        }, {
          iconName: 'prove',
          routeTo: '/app/data/prove-it',
          routeText: 'nav.proveIt'
        }, {
          iconName: 'contact',
          routeTo: '/app/data/contact',
          routeText: 'nav.contact'
        }, {
          iconName: 'training',
          routeTo: '/app/data/training',
          routeText: 'nav.training'
        }, {
          iconName: 'delivery',
          routeTo: '/app/data/delivery',
          routeText: 'nav.delivery'
        }, {
          iconName: 'thermometer',
          routeTo: '/app/data/probe',
          routeText: 'nav.probe'
        }, {
          iconName: 'problem',
          routeTo: '/app/data/problem',
          routeText: 'nav.problem'
        }, {
          iconName: 'supplierslist',
          routeTo: '/app/data/supplier',
          routeText: 'nav.supplier'
        }, {
          iconName: 'open',
          routeTo: '/app/data/opening',
          routeText: 'nav.opening'
        }, {
          iconName: 'close',
          routeTo: '/app/data/closing',
          routeText: 'nav.closing'
        }, {
          iconName: 'monthly',
          routeTo: '/app/data/monthly-review',
          routeText: 'nav.monthly-review'
        }, {
          iconName: 'allergens',
          routeTo: '/app/data/dishes-allergen',
          routeText: 'nav.dishes-allergen'
        }, {
          iconName: 'foodhandling',
          routeTo: '/app/data/food-handling',
          routeText: 'nav.food-handling'
        }
      ] as NavigationItem[],
      interceptor: {} as any,
      showMissingCardsModal: true as boolean,
      sidebarCollapsed: false,
      sidebarMobileCollapsed: false
    }
  },
  computed: {
    missingCardsBranches () {
      return this.$store.state.loggedUser && this.$store.state.loggedUser.branches ? this.$store.state.loggedUser.branches.filter((branch: Branch) => !branch.hasCard && !VueCookieNext.getCookie('missingCardsClosed')) : []
    },
    unpaidBranches () {
      const now = new Date()
      return this.$store.state.branches && this.$store.state.branches.length > 0
        ? this.$store.state.branches.filter((branch: Branch) => {
          if (!branch.blocked_from) return false
          else if (!branch.blocked_to) return true
          const blockedFrom = new Date(branch.blocked_from)
          const blockedTo = new Date(branch.blocked_to)
          const isBlocked = now.getTime() >= blockedFrom.getTime() && now.getTime() < blockedTo.getTime()
          // return branch.isSuspended || isBlocked
          return isBlocked
        })
        : []
    }
    // suspendedBranches () {
    //   return this.$store.state.branches
    //     ? this.$store.state.branches.filter((branch: Branch) => branch.isSuspended)
    //     : []
    // }
  },
  methods: {
    closeMissingCardsModal () {
      VueCookieNext.setCookie('missingCardsClosed', 'true')
      this.showMissingCardsModal = false
    },
    goToPayments () {
      this.closeMissingCardsModal()
      this.$router.push({ path: '/app/settings', query: { section: 'settings.payments' } })
    }
  }
})
