import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "account" }
const _hoisted_2 = { class: "main-sidebar fixed start-0 bg-backgroundColor z-10" }
const _hoisted_3 = {
  key: 0,
  class: "bg-red-100 border-l-4 border-red-500 text-red-700 p-4",
  role: "alert"
}
const _hoisted_4 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["main flex border-t border-backgroundColor", { 'collapsed': _ctx.sidebarCollapsed, 'collapsedMobile': _ctx.sidebarMobileCollapsed }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.$store.state.loggedUser)
          ? (_openBlock(), _createBlock(_component_Sidebar, {
              key: 0,
              class: "hidden md:flex",
              dontToggleOnLinkClick: true,
              navItems: _ctx.navItems,
              sidebarCollapsed: _ctx.sidebarCollapsed,
              onSidebarCollapsed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebarCollapsed = $event))
            }, null, 8, ["navItems", "sidebarCollapsed"]))
          : _createCommentVNode("", true),
        (_ctx.$store.state.loggedUser)
          ? (_openBlock(), _createBlock(_component_Sidebar, {
              key: 1,
              class: "flex md:hidden",
              refName: "sidebarMobile",
              navItems: _ctx.navItems,
              sidebarCollapsed: _ctx.sidebarMobileCollapsed,
              onSidebarCollapsed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sidebarMobileCollapsed = $event))
            }, null, 8, ["navItems", "sidebarCollapsed"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["main-container content w-full py-2 px-2 pb-32 lg:py-5 lg:px-10 overflow-auto", { 'collapsed': _ctx.sidebarCollapsed, 'collapsedMobile': _ctx.sidebarMobileCollapsed }])
      }, [
        (_ctx.unpaidBranches.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('settings.unpaidBranchesMessage')), 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unpaidBranches, (branch, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: "mt-2"
                  }, _toDisplayString(branch.name), 1))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view)
      ], 2)
    ], 2),
    (_ctx.missingCardsBranches.length > 0 && _ctx.showMissingCardsModal)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          title: "account.missingCard",
          onClosed: _ctx.closeMissingCardsModal,
          class: "z-30"
        }, {
          footer: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "primary",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToPayments && _ctx.goToPayments(...args)))
            }, _toDisplayString(_ctx.$t('account.goToPayments')), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.missingCardsBranches, (branch, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "text-left text-lg py-2"
              }, _toDisplayString(branch.name), 1))
            }), 128))
          ]),
          _: 1
        }, 8, ["onClosed"]))
      : _createCommentVNode("", true)
  ]))
}