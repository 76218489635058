import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "p-5 text-2xl flex items-center gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = { class: "p-5 max-w-screen" }
const _hoisted_4 = { class: "p-5 flex items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "overflow-auto background fixed top-0 start-0 z-50 w-screen max-w-screen h-screen flex items-start justify-center",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close($event)))
  }, [
    _createElementVNode("div", {
      class: "border rounded bg-white",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.windowClicked($event)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t(_ctx.title)), 1)
          ]),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.subtitle)), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "ms-auto cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closed')))
        }, "✕")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "footer"),
        (!_ctx.hideCancel)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closed')))
            }, _toDisplayString(_ctx.$t('cancel')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}