
import { defineComponent, PropType } from 'vue'
import BaseIcon from '@/shared/components/base-icon/BaseIcon.vue'
import { NavigationItem } from '@/shared/interfaces/navigation.model'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    companyTitle: {
      type: String
    },
    navItems: {
      type: Object as PropType<NavigationItem[]>,
      required: true
    },
    sidebarCollapsed: Boolean,
    dontToggleOnLinkClick: Boolean
  },
  emits: ['sidebarCollapsed'],
  methods: {
    handleOutsideClick (event: Event): void {
      const el = this.$refs.sidebar as HTMLElement
      if (el && !el.contains(event.target as Node) && !this.sidebarCollapsed) {
        this.$emit('sidebarCollapsed', true)
      }
    },
    emitClick () {
      if (!this.dontToggleOnLinkClick) {
        this.$emit('sidebarCollapsed', true)
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }
})
