<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M14.1666 18.3237V16.6571C14.1666 15.773 13.8154 14.9252 13.1903 14.3C12.5652 13.6749 11.7173 13.3237 10.8333 13.3237H4.16659C3.28253 13.3237 2.43468 13.6749 1.80956 14.3C1.18444 14.9252 0.833252 15.773 0.833252 16.6571V18.3237"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.50008 9.9904C9.34103 9.9904 10.8334 8.49801 10.8334 6.65706C10.8334 4.81611 9.34103 3.32373 7.50008 3.32373C5.65913 3.32373 4.16675 4.81611 4.16675 6.65706C4.16675 8.49801 5.65913 9.9904 7.50008 9.9904Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.1667 18.3236V16.6569C19.1662 15.9184 18.9204 15.2009 18.4679 14.6172C18.0154 14.0335 17.3819 13.6166 16.6667 13.4319"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 3.43195C14.0503 3.61553 14.6858 4.03253 15.1396 4.6172C15.5935 5.20188 15.8398 5.92097 15.8398 6.66111C15.8398 7.40125 15.5935 8.12035 15.1396 8.70502C14.6858 9.28969 14.0503 9.70669 13.3333 9.89028"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20"
          height="20"
          fill="none"
          transform="translate(0 0.82373)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
