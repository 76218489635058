import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/right.png'


const _withScopeId = n => (_pushScopeId("data-v-6fda122a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "z-10 flex",
  ref: "sidebar"
}
const _hoisted_2 = { class: "w-60 ms-2 overflow-auto pb-32" }
const _hoisted_3 = { class: "toggle-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", {
      class: _normalizeClass(["sidebar flex justify-between text-textColor", {'w-0 overflow-x-hidden': _ctx.sidebarCollapsed}])
    }, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (navItem, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createVNode(_component_router_link, {
              onClick: _ctx.emitClick,
              to: navItem.routeTo,
              class: "flex flex-wrap items-center p-2 hover:bg-secondary hover:text-primary rounded sidebar-nav-link"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseIcon, {
                  name: navItem.iconName,
                  class: "me-2"
                }, null, 8, ["name"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t(navItem.routeText)), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "to"])
          ]))
        }), 128))
      ])
    ], 2),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sidebarCollapsed', !_ctx.sidebarCollapsed))),
      class: "toggle-button"
    }, [
      _createElementVNode("img", {
        class: _normalizeClass(["mb-2", { 'transform rotate-180': !_ctx.sidebarCollapsed }]),
        width: "10",
        height: "10",
        fill: "none",
        src: _imports_0
      }, null, 2),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.sidebarCollapsed ? _ctx.$t('nav.showSideMenu') : _ctx.$t('nav.hideSideMenu')), 1),
      _createElementVNode("img", {
        class: _normalizeClass(["mt-2", { 'transform rotate-180': !_ctx.sidebarCollapsed }]),
        width: "10",
        height: "10",
        fill: "none",
        src: _imports_0
      }, null, 2)
    ])
  ], 512))
}